@import "../../../styles/anim/anim-FadeIn.css";

.HeaderView {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.HeaderView__logo {
    margin-top: 3.5%;
    display: grid;
    place-items: center;
    mix-blend-mode: difference;
    color: white;
}

.HeaderView__logo--white {
    filter: invert(1) grayscale(1) contrast(9);
}