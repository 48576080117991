@import "../../styles/anim/anim-FadeIn.css";
@import "../../styles/anim/anim-FadeOut.css";

.toast {
    position: absolute;
    display: grid;
    place-items: center;
    height: 2.5em;
    top: 90vh;
    left: auto;
    right: auto;
    border-radius: 25px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    background: rgba(230, 230, 230, 0.7);
    padding: 10px;

    animation: fadeIn 500ms ease-in;


    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.toast-message {
    color: #222;
}

.hide {
    opacity: 0;
    animation: fadeOut 500ms ease-in;
}

