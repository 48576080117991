.MediaContentView {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.MediaContentView__video {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}

.MediaContentView__img {
    position: absolute;
    transition: opacity 1800ms ease 0ms;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    animation: fadeIn 1500ms ease-in-out;
}

.MediaContentView__img > img {
    object-fit: cover;
    min-height: 100vh;
    min-width: 100%;
}

.LoadingWrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.CrowdfundingExtCard iframe {
    height: 550px;
    max-width: 100%;
}
