@keyframes slideIn {
    0% {
        margin-left: -300px;
    }
    100% {
        margin-left: 0;
    }
}

.DownloadFab {
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 0.4;
}

.DownloadFab__container {
    width: 100%;
    display: grid;
    place-items: start;
    padding-left: 20px;
}

.DownloadFab__button {
    --fabAccentColor: white;
    display: grid;
    place-items: center;
    padding: 5px 15px;
    color: var(--fabAccentColor);
    border-radius: 180px;
    border: var(--fabAccentColor) 2px solid;
    background: rgba(25, 25, 25, 0.7);
    backdrop-filter: blur(10px);
    animation: 600ms ease-in-out slideIn;
}

.DownloadFab__button h5 {
    font-size: 16px;
}

.DownloadFab__button:hover {
    --fabAccentColor: white;
    background: rgba(120, 120, 120, 0.5);
    cursor: pointer;
}