

.MobileHeaderLayout {
    --MobileHeaderLayoutHeight: 55px;

    min-height: var(--MobileHeaderLayoutHeight);
    display: flex;
    justify-content: space-between;
    padding-inline: 20px;
}

.MobileHeaderLayout .MobileNavigationWidget__menuIcon {
    display: grid;
    place-items: center;
    min-height: var(--MobileHeaderLayoutHeight);
    cursor: pointer;
    min-width: 35px;
    width: 100%;
}

.MobileHeaderLayout .MobileNavigationWidget__menuIcon svg {
    transform: scale(1.15);
}

.MobileHeaderLayout .MobileNavigationWidget {
}

.MobileHeaderLayout .LogoWidget > svg {
    max-height: 22px;
    max-width: 210px;
}

.MobileHeaderLayout .LogoIcon {
    display: grid;
    place-items: center;
    padding-right: 12px;
}

.MobileHeaderLayout .LogoIcon svg {
    height: 22px;
}

.MobileHeaderLayout .LogoWidget {
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
}