@import "styles/anim/anim-FadeIn.css";
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css";
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Cairo', sans-serif;
  background: black;
}

html {
  margin: 0;
  padding: 0;
  background: black;
  font-family: 'Cairo', sans-serif;
  scroll-behavior: smooth;

  /*scroll-snap-type: y mandatory;*/
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 2.8em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 0.8em;
}


.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noScroll {
  overflow: hidden;
  height: 100vh;
}

.App {
  text-align: center;
  animation: fadeIn 900ms ease-in;
  /*scroll-snap-type: y mandatory;*/
  width: 100%;
  overflow: hidden;
}
