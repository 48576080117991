

@keyframes slideInNFadeIn {
    0% {
        opacity: 0;
        margin-top: 40vh;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

.AboutApp--show {
    animation: slideInNFadeIn 600ms ease-in-out;

    margin-top: 0;
    opacity: 1;
}

.AboutApp--hide {
    animation: fadeOut 1s ease-in-out;
    opacity: 0;
    z-index: 0;
}

.AboutAppWidget {
    display: grid;
    place-items: center;

    /*scroll-snap-align: start;*/
    padding-top: 15px;

    --imgWidth: 325px;

}

.AboutApp--container {
    display: grid;
    place-items: center;
}

.AboutAppIntro {
    display: grid;
    place-items: center;
    padding: 0 15px 30px 15px;
}

.AboutAppSocialize {
    display: grid;
    place-items: center;
    background: #111;
    padding: 30px 15px;
}

.AboutAppWatch {
    display: grid;
    place-items: center;
    padding: 30px 15px;
}

.AboutAppVault {
    display: grid;
    place-items: center;
    background: #111;
    padding: 30px 15px;
}

.AboutAppSmartPrice {
    display: grid;
    place-items: center;
    padding: 30px 15px;
    margin-bottom: 30px;
}


.AboutAppHeader {
    width: 80%;
    margin: 25px 0;
}

.AboutAppHeader h5 {
    margin-top: 15px;
    font-family: "Inter", sans-serif;
    font-weight: normal;
}


.AboutAppIntroImg {
    border-radius: 20px;
    width: 90%;
    margin-top: 15px;
    display: grid;
    place-items: center;
}

.AboutAppSocializeImg {
    border-radius: 20px;
    width: var(--imgWidth);
    margin-top: 15px;
}

.AboutAppIntroImg img {
    width: var(--imgWidth);
    height: 100%;
    border-radius: 20px;
}

.AboutAppSmartPriceImg {
    border-radius: 20px;
    width: var(--imgWidth);
    margin-top: 15px;
}

.AboutAppSocializeImg img {
    width: var(--imgWidth);
    height: 100%;
    border-radius: 20px;
}

.AboutAppVaultImg {
    border-radius: 20px;
    margin-top: 15px;
    width: var(--imgWidth);
}

.AboutAppVaultImg img {
    width: var(--imgWidth);
    height: 100%;
    border-radius: 20px;
}

.AboutAppWatchImg {
    border-radius: 20px;
    width: var(--imgWidth);
    margin-top: 15px;
}

.AboutAppWatchImg img {
    height: 100%;
    width: var(--imgWidth);
    border-radius: 20px;
}

.AboutAppSmartPriceImg img {
    height: 100%;
    width: var(--imgWidth);
    border-radius: 20px;
}

.AboutAppIntroText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppWidget p {
    text-align: start;
    max-width: 65ch;
}

.AboutAppSocializeText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppWatchText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppVaultText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppSmartPriceText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppWidget h4 {
    font-size: 28px;
    font-weight: bold;
    margin: 15px 0;
}

.GetNotifiedBtn {
    position: static;
    font-weight: normal;
    
}

.GetNotifiedBtn--wrapper {
    width: 100%;
    display: grid;
    place-items: center;
    padding: 45px;
}

.PromoVid__promoVid {
    width: 100%;
    max-width: 1000px;
    min-height: 40vh;
    overflow: hidden;
}

.PromoVid {
    display: grid;
    place-items: center;
    padding: 4px 2px;
    margin: 30px;
    width: 100%;
    max-width: 1000px;
    overflow: hidden;
}

@media screen and (min-width: 600px) {

    .imgContainer {
        display: grid;
        place-items: center;
    }

    .contentContainer {
        max-width: 1200px;
        margin-bottom: 25px;
    }

    .AboutAppHeader {
        width: 80%;
        padding-top: 35px;
    }

    .AboutAppIntroImg {
        border-radius: 20px;
        width: var(--imgWidth);
        margin-top: 15px;
    }

    .AboutAppIntroImg img {
        width: 375px;
        border-radius: 20px;
    }

    .AboutAppIntroText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15px;
    }

    .AboutAppIntro {
        padding: 25px;
        margin: 35px 0;
    }

    .AboutAppSocializeImg {
        border-radius: 20px;
        width: var(--imgWidth);
        margin-top: 15px;
    }

    .AboutAppSocializeImg img {
        width: var(--imgWidth);
        border-radius: 20px;
    }

    .AboutAppSmartPriceImg {
        border-radius: 20px;
        margin-top: 15px;
        width: var(--imgWidth);
    }

    .AboutAppSmartPriceImg img {
        border-radius: 20px;
        width: var(--imgWidth);
    }

    .AboutAppVaultImg {
        width: var(--imgWidth);
        border-radius: 20px;
        margin-top: 15px;
    }

    .AboutAppVaultImg img {
        border-radius: 20px;
        width: var(--imgWidth);
    }

    .AboutAppWatchImg {
        border-radius: 20px;
        width: var(--imgWidth);
        margin-top: 15px;
    }

    .AboutAppWatchImg img {
        width: 375px;
        border-radius: 20px;
    }

    .AboutAppSocializeText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppWatchText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppVaultText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppSmartPriceText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppSocialize {
        padding: 25px 25px 85px 25px;
        margin: 35px 0 0 0;
        width: 100%;
    }

    .AboutAppSmartPrice {
        padding: 0 25px 55px 25px;
        margin: 35px 0;
    }

    .AboutAppWatch {
        padding: 0 25px 55px 25px;
        margin: 35px 0;
    }

    .AboutAppVault {
        padding: 25px 25px 85px 25px;
        margin: 35px 0 0 0;
        width: 100%;
    }

    .AboutAppWidget h4 {
        margin: 35px 0;
        font-weight: bold;
        font-size: 38px;
    }
}