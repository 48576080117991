

.DesktopHeaderLayout {
    --DesktopHeaderLayoutHeight: 70px;

    min-height: var(--DesktopHeaderLayoutHeight);
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

.DesktopHeaderLayout .LogoWidget {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.DesktopHeaderLayout .LogoIcon {
    --LogoIconHeight: 29px;
    display: grid;
    place-items: center;
    height: var(--LogoIconHeight);
    padding: 0 14px 0 25px;
}
.DesktopHeaderLayout .LogoIcon svg {
    height: var(--LogoIconHeight);
    display: grid;
    place-items: center;
}

.DesktopHeaderLayout .NavigationWidget {
    display: flex;
}

.NavigationWidget__navBtn {
    margin: 0 1ch;
    padding: 0.5ch 3ch;
    border-radius: 180px;
    display: grid;
    place-items: center;
    height: 100%;
}

.NavigationWidget__navBtn h5:hover {
    border-bottom: #333 3px solid;
    cursor: pointer;
    display: grid;
    place-items: center;
}
