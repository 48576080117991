


.AboutView {
    background: black;
    width: 100%;

    color: white;
}

.AboutAppHeader {
    border-bottom: #555 3px solid;
    padding: 4px;
}