

.NavigationSocialMediaWidget {
    display: flex;
    align-content: center;
    height: 100%;
    margin: 0 15px;
}

.NavigationSocialMediaWidget__navBtn {
    display: grid;
    place-items: center;
    padding: 0 10px;
    color: #ddd;
    cursor: pointer;
}

.NavigationSocialMediaWidget__navBtn:hover {
    color: white;
}