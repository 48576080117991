

.CrowdfundingView {
    width: 100%;
    /*background: #fefefe;*/
    background: url("https://i.ibb.co/dmY3z8C/Screenshot-2021-08-23-at-19-24-59.png") repeat;
    padding: 100px 0;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-top: #ddd 15px solid;
    border-bottom: #222 15px solid;
    min-height: 100vh;
}

.CrowdfundingView .columns {
    max-width: 1000px;
}

.CrowdfundingTextWidget__wrapper {
    display: grid;
    place-items: center;
    background: rgba(255, 255, 255, 0.69);
    padding: 40px;
    border-radius: 25px;
    box-shadow: 0 0 6px 12px rgba(0, 0, 0, 0.008);
}

.CrowdfundingTextWidget {
    width: 100%;
    display: grid;
    place-items: center;
}

@keyframes openText {
    0% {
        transform: scale(0.7);
        margin-top: 100%;
    }
    100% {
        transform: scale(1);
        margin-top: 0;
    }
}

.CrowdfundingTextWidget__title {
    overflow: hidden;
    height: 201px;
}

@media  {

}

.CrowdfundingTextWidget__title__h2.open {
    animation: openText 400ms linear;
}

.CrowdfundingTextWidget__txtContent p {
    width: 100%;
}

.CrowdfundingTextWidget__txtContent {
    margin: 3em 0;
}

.CrowdfundingTextWidget__joinBtn {
    border-radius: 180px;
    padding: 1ch 3ch;
    background: #18A0FB;
    color: white;
    margin: 1em 0;
    box-shadow: 0 2px 9px 4px rgba(0, 0, 0, 0.02);
}

.CrowdfundingTextWidget__joinBtn:hover {
    cursor: pointer;
    transform: scale(1.01);
    background: #4DB7FF;
    color: white;
}

.CrowdfundingExtCard {
    max-width: 100%;
    display: grid;
    place-items: center;
}

.CrowdfundingExtCard .fiFrame {
    height: 685px;
    width: 402px;
    border-radius: 10px;
    background: white;
    border: white 10px solid;
}

.CrowdfundingTextWidget__markerContainer {
    width: 100%;
    display: grid;
    place-items: center;
    padding: 0 0 20px 0;
}

.CrowdfundingTextWidget__marker {
    background: #18A0FB;
    width: 4px;
    height: 18px;
}