


.StaffView {
    width: 100%;
    background: url("https://i.ibb.co/zFRSxqD/b7289a03.png") repeat;
    display: grid;
    place-items: center;
    min-height: 100vh;
    padding: 0 0 100px 0;
    border-top: #ddd 15px solid;
    border-bottom: #222 15px solid;
}

.StaffView p {
    text-align: start;
}

.StaffView__container {
    max-width: 1130px;
    padding: 25px;
}

.StaffView__title {
    width: 100%;
    color: black;
    display: grid;
    place-items: center;
    padding: 45px 0;
}

.StaffView__title h2 {
    font-weight: 500;
    text-shadow: 0 0 3px #eee, 0 0 5px #ddd;
}

.StaffView .card-image img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.card .media-content {
    overflow-y: hidden;
}

.card .content {
    font-size: 14px;
}

.StaffView__marker {
    background: #18A0FB;
    width: 4px;
    height: 18px;
    margin-bottom: 20px;
}

.StaffView .card {
    overflow: hidden;
    border-radius: 15px;
    height: 100%;
}

.StaffView .card .content b {
    position: absolute;
    top: 90%;
    width: 100%;
    left: 0;
}
