

.PartnersView {
    display: grid;
    place-items: center;
    padding: 50px 10px;
    color: #ddd;
}

.PartnersView--wrapper {
    width: 100%;
    padding: 50px 10px;
}

.PartnerLogo {
    display: grid;
    place-items: center;
    opacity: 0.75;
}

.PartnersView__title {
    padding: 35px 0;
}

.PartnerLogo:hover {
    opacity: 1;
    cursor: pointer;
}

.PartnerLogo svg {
    object-fit: contain;
    width: 100px;
    max-height: 85px;
}