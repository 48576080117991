

.GetNotifiedView {
    background: #eee;
    width: 35em;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 2%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
}

.GetNotifiedView--mobile {
    background: #eee;
    width: 95%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 2%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
}

.GetNotifiedView--background {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(200, 200, 200, 0.7);
    z-index: 10;
    display: grid;
    place-items: center;


    -webkit-backdrop-filter: blur(8px);
    -moz-backdrop-filter: blur(8px);
    -o-backdrop-filter: blur(8px);
    -ms-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.RegisterViewWidget h2 {
    border-bottom: #ddd 1px solid;
    margin-bottom: 4%;
    padding: 2%;
}

.RegisterViewWidget h5 {
    margin: 2% 3% 5%;
}

.RegisterViewWidget > p {
    margin: 2%;
}

.RegisterViewWidget .separator {
    height: 0.2em;
}

.RegisterViewWidget form {
    display: flex;
    width: 100%;
    margin-top: 2%;
}

.RegisterViewWidget .field {
    flex: 1;
}

.GetNotifiedView button {
    position: relative;
}

.SuccessViewWidget {
    color: #000;
    position: absolute;
    /*background: #21bf73;*/
    background: #eee;
    backdrop-filter: blur(10px);
    z-index: 0.95;
    width: 35em;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 4%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    animation: fadeIn 200ms ease-in;
}

.SuccessViewWidget--mobile {
    color: #000;
    position: absolute;
    /*background: #21bf73;*/
    background: #eee;
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 0.95;
    width: 95%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 4%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    animation: fadeIn 200ms ease-in;
}

.SuccessViewWidget h2, .SuccessViewWidget--mobile h2 {
    margin-bottom: 7%;
    border-bottom: #555 1px solid;
}

.SuccessViewWidget h5, .SuccessViewWidget--mobile h5 {
    margin-bottom: 6%;
}

.SuccessViewWidget p, .SuccessViewWidget--mobile p {
    margin-bottom: 5%;
}

.google-btn--wrapper {
    display: grid;
    place-items: center;
    margin: 4% 0 1% 0;
}

.google-btn {
    display: flex;
    align-items: center;
    border-radius: 180px;
    -webkit-border-radius: 180px;
    -moz-border-radius: 180px;
    border: #ddd 2px solid;
}

.google-btn:hover {
    cursor: pointer;
    background: #f1f1f1;
}

.google-btn img {
    padding: 2%;
    width: 4ch;
}

.google-btn p {
    width: 20ch;
}
