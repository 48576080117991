@keyframes LandingPageOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes LandingPageIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.LandingPageView {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
    z-index: 0;
    /*scroll-snap-align: start;*/
}

.LandingPageView.fixed {
    top: 0;
    position: sticky;
    z-index: 5;
}

.LandingPageView.show {
    animation: LandingPageIn 600ms ease-in;

    opacity: 1;
}

.LandingPageView.hide {
    animation: LandingPageOut 600ms ease-in;
    opacity: 0;
}

.scrollIcon--wrapper p {
    font-size: 14px;
}

.scrollIcon{

    box-sizing: border-box;
    border: solid 4px #ddd;
    display: block;
    position: relative;
    height: 48px;
    width: 32px;
    border-radius: 32px;
    opacity: 0.25;
}
.scrollIcon::before{
    content: "";
    width: 8px;
    height: 8px;
    background-color: #ddd;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100%;
    animation: mouse 3s infinite;
}

@keyframes mouse{
    0%  {transform: none;             opacity: 1;}
    40% {transform: translateY(16px);            }
    80% {transform: translateY(16px); opacity: 0;}
    100%{transform: translateY(16px); opacity: 0;}
}

.scrollIcon__container {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: grid;
    place-items: center;
}

.scrollIcon--wrapper {
    position: absolute;
    bottom: 35px;
    width: 100%;
    display: grid;
    place-items: center;
    color: #555;
}
