

.FooterView {
    min-height: 150px;
    max-height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
    background: #eee;
    padding: 45px 0;
    border-top: #ddd 15px solid;
    border-bottom: #222 15px solid;
}

.FooterLogoWidget {
    padding: 25px 0 0 0;
}

.FooterStatementWidget p {
    font-size: 0.8em;
}