

.OutroMessageView {
    position: absolute;
    width: 100%;
    height: calc(100vh - 20%);
    overflow: hidden;
    padding: 10% 10%;
    top: 20%;
    display: block;
    z-index: 0.88;
}

.OutroMessageView__text {
    color: white;
    font-size: 1.25em;
    width: 40%;
    animation: fadeIn 2.4s ease-in-out;
}
