

@keyframes HeaderSlideInFromTop {
    0% {
        top: -100%;
    }
    100%{
        top: 0;
    }
}

@keyframes HeaderSlideOutTop {
    0% {
        top: 0;
    }
    100%{
        top: -100%;
    }
}

.Header {
    top: 0;
    position: fixed;
    background: rgba(144, 144, 144, 0.64);
    width: 100%;
    backdrop-filter: blur(8px);
    z-index: 9;
    color:black;
}

.Header.show {
    animation: HeaderSlideInFromTop 800ms ease;
}

.Header.hide {
    animation: HeaderSlideOutTop 800ms ease;
}