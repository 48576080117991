@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css);
@import url(https://fonts.googleapis.com/css2?family=Cairo&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);


@-webkit-keyframes slideInNFadeIn {
    0% {
        opacity: 0;
        margin-top: 40vh;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes slideInNFadeIn {
    0% {
        opacity: 0;
        margin-top: 40vh;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

.AboutApp--show {
    -webkit-animation: slideInNFadeIn 600ms ease-in-out;
            animation: slideInNFadeIn 600ms ease-in-out;

    margin-top: 0;
    opacity: 1;
}

.AboutApp--hide {
    -webkit-animation: fadeOut 1s ease-in-out;
            animation: fadeOut 1s ease-in-out;
    opacity: 0;
    z-index: 0;
}

.AboutAppWidget {
    display: grid;
    place-items: center;

    /*scroll-snap-align: start;*/
    padding-top: 15px;

    --imgWidth: 325px;

}

.AboutApp--container {
    display: grid;
    place-items: center;
}

.AboutAppIntro {
    display: grid;
    place-items: center;
    padding: 0 15px 30px 15px;
}

.AboutAppSocialize {
    display: grid;
    place-items: center;
    background: #111;
    padding: 30px 15px;
}

.AboutAppWatch {
    display: grid;
    place-items: center;
    padding: 30px 15px;
}

.AboutAppVault {
    display: grid;
    place-items: center;
    background: #111;
    padding: 30px 15px;
}

.AboutAppSmartPrice {
    display: grid;
    place-items: center;
    padding: 30px 15px;
    margin-bottom: 30px;
}


.AboutAppHeader {
    width: 80%;
    margin: 25px 0;
}

.AboutAppHeader h5 {
    margin-top: 15px;
    font-family: "Inter", sans-serif;
    font-weight: normal;
}


.AboutAppIntroImg {
    border-radius: 20px;
    width: 90%;
    margin-top: 15px;
    display: grid;
    place-items: center;
}

.AboutAppSocializeImg {
    border-radius: 20px;
    width: var(--imgWidth);
    margin-top: 15px;
}

.AboutAppIntroImg img {
    width: var(--imgWidth);
    height: 100%;
    border-radius: 20px;
}

.AboutAppSmartPriceImg {
    border-radius: 20px;
    width: var(--imgWidth);
    margin-top: 15px;
}

.AboutAppSocializeImg img {
    width: var(--imgWidth);
    height: 100%;
    border-radius: 20px;
}

.AboutAppVaultImg {
    border-radius: 20px;
    margin-top: 15px;
    width: var(--imgWidth);
}

.AboutAppVaultImg img {
    width: var(--imgWidth);
    height: 100%;
    border-radius: 20px;
}

.AboutAppWatchImg {
    border-radius: 20px;
    width: var(--imgWidth);
    margin-top: 15px;
}

.AboutAppWatchImg img {
    height: 100%;
    width: var(--imgWidth);
    border-radius: 20px;
}

.AboutAppSmartPriceImg img {
    height: 100%;
    width: var(--imgWidth);
    border-radius: 20px;
}

.AboutAppIntroText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppWidget p {
    text-align: start;
    max-width: 65ch;
}

.AboutAppSocializeText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppWatchText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppVaultText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppSmartPriceText {
    padding: 40px 20px;
    font-family: "Inter", sans-serif;
}

.AboutAppWidget h4 {
    font-size: 28px;
    font-weight: bold;
    margin: 15px 0;
}

.GetNotifiedBtn {
    position: static;
    font-weight: normal;
    
}

.GetNotifiedBtn--wrapper {
    width: 100%;
    display: grid;
    place-items: center;
    padding: 45px;
}

.PromoVid__promoVid {
    width: 100%;
    max-width: 1000px;
    min-height: 40vh;
    overflow: hidden;
}

.PromoVid {
    display: grid;
    place-items: center;
    padding: 4px 2px;
    margin: 30px;
    width: 100%;
    max-width: 1000px;
    overflow: hidden;
}

@media screen and (min-width: 600px) {

    .imgContainer {
        display: grid;
        place-items: center;
    }

    .contentContainer {
        max-width: 1200px;
        margin-bottom: 25px;
    }

    .AboutAppHeader {
        width: 80%;
        padding-top: 35px;
    }

    .AboutAppIntroImg {
        border-radius: 20px;
        width: var(--imgWidth);
        margin-top: 15px;
    }

    .AboutAppIntroImg img {
        width: 375px;
        border-radius: 20px;
    }

    .AboutAppIntroText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15px;
    }

    .AboutAppIntro {
        padding: 25px;
        margin: 35px 0;
    }

    .AboutAppSocializeImg {
        border-radius: 20px;
        width: var(--imgWidth);
        margin-top: 15px;
    }

    .AboutAppSocializeImg img {
        width: var(--imgWidth);
        border-radius: 20px;
    }

    .AboutAppSmartPriceImg {
        border-radius: 20px;
        margin-top: 15px;
        width: var(--imgWidth);
    }

    .AboutAppSmartPriceImg img {
        border-radius: 20px;
        width: var(--imgWidth);
    }

    .AboutAppVaultImg {
        width: var(--imgWidth);
        border-radius: 20px;
        margin-top: 15px;
    }

    .AboutAppVaultImg img {
        border-radius: 20px;
        width: var(--imgWidth);
    }

    .AboutAppWatchImg {
        border-radius: 20px;
        width: var(--imgWidth);
        margin-top: 15px;
    }

    .AboutAppWatchImg img {
        width: 375px;
        border-radius: 20px;
    }

    .AboutAppSocializeText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppWatchText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppVaultText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppSmartPriceText {
        display: grid;
        place-items: center;
        height: 100%;
        font-family: "Inter", sans-serif;
        padding: 15%;
    }

    .AboutAppSocialize {
        padding: 25px 25px 85px 25px;
        margin: 35px 0 0 0;
        width: 100%;
    }

    .AboutAppSmartPrice {
        padding: 0 25px 55px 25px;
        margin: 35px 0;
    }

    .AboutAppWatch {
        padding: 0 25px 55px 25px;
        margin: 35px 0;
    }

    .AboutAppVault {
        padding: 25px 25px 85px 25px;
        margin: 35px 0 0 0;
        width: 100%;
    }

    .AboutAppWidget h4 {
        margin: 35px 0;
        font-weight: bold;
        font-size: 38px;
    }
}
.GetNotifiedBtnView p {
    text-align: center;
}

.GetNotifiedBtnView {
    border-radius: 180px;
    -webkit-border-radius: 180px;
    -moz-border-radius: 180px;
    background: rgba(50, 50, 50, 0.75);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0.5em;
    width: 8em;
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
}


.GetNotifiedBtnView:hover {
    cursor: pointer;
    background: rgba(230, 230, 230, 0.6);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: black;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}



.AboutView {
    background: black;
    width: 100%;

    color: white;
}

.AboutAppHeader {
    border-bottom: #555 3px solid;
    padding: 4px;
}


.CrowdfundingView {
    width: 100%;
    /*background: #fefefe;*/
    background: url("https://i.ibb.co/dmY3z8C/Screenshot-2021-08-23-at-19-24-59.png") repeat;
    padding: 100px 0;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-top: #ddd 15px solid;
    border-bottom: #222 15px solid;
    min-height: 100vh;
}

.CrowdfundingView .columns {
    max-width: 1000px;
}

.CrowdfundingTextWidget__wrapper {
    display: grid;
    place-items: center;
    background: rgba(255, 255, 255, 0.69);
    padding: 40px;
    border-radius: 25px;
    box-shadow: 0 0 6px 12px rgba(0, 0, 0, 0.008);
}

.CrowdfundingTextWidget {
    width: 100%;
    display: grid;
    place-items: center;
}

@-webkit-keyframes openText {
    0% {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        margin-top: 100%;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        margin-top: 0;
    }
}

@keyframes openText {
    0% {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        margin-top: 100%;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        margin-top: 0;
    }
}

.CrowdfundingTextWidget__title {
    overflow: hidden;
    height: 201px;
}

@media  {

}

.CrowdfundingTextWidget__title__h2.open {
    -webkit-animation: openText 400ms linear;
            animation: openText 400ms linear;
}

.CrowdfundingTextWidget__txtContent p {
    width: 100%;
}

.CrowdfundingTextWidget__txtContent {
    margin: 3em 0;
}

.CrowdfundingTextWidget__joinBtn {
    border-radius: 180px;
    padding: 1ch 3ch;
    background: #18A0FB;
    color: white;
    margin: 1em 0;
    box-shadow: 0 2px 9px 4px rgba(0, 0, 0, 0.02);
}

.CrowdfundingTextWidget__joinBtn:hover {
    cursor: pointer;
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    background: #4DB7FF;
    color: white;
}

.CrowdfundingExtCard {
    max-width: 100%;
    display: grid;
    place-items: center;
}

.CrowdfundingExtCard .fiFrame {
    height: 685px;
    width: 402px;
    border-radius: 10px;
    background: white;
    border: white 10px solid;
}

.CrowdfundingTextWidget__markerContainer {
    width: 100%;
    display: grid;
    place-items: center;
    padding: 0 0 20px 0;
}

.CrowdfundingTextWidget__marker {
    background: #18A0FB;
    width: 4px;
    height: 18px;
}



.StaffView {
    width: 100%;
    background: url("https://i.ibb.co/zFRSxqD/b7289a03.png") repeat;
    display: grid;
    place-items: center;
    min-height: 100vh;
    padding: 0 0 100px 0;
    border-top: #ddd 15px solid;
    border-bottom: #222 15px solid;
}

.StaffView p {
    text-align: start;
}

.StaffView__container {
    max-width: 1130px;
    padding: 25px;
}

.StaffView__title {
    width: 100%;
    color: black;
    display: grid;
    place-items: center;
    padding: 45px 0;
}

.StaffView__title h2 {
    font-weight: 500;
    text-shadow: 0 0 3px #eee, 0 0 5px #ddd;
}

.StaffView .card-image img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.card .media-content {
    overflow-y: hidden;
}

.card .content {
    font-size: 14px;
}

.StaffView__marker {
    background: #18A0FB;
    width: 4px;
    height: 18px;
    margin-bottom: 20px;
}

.StaffView .card {
    overflow: hidden;
    border-radius: 15px;
    height: 100%;
}

.StaffView .card .content b {
    position: absolute;
    top: 90%;
    width: 100%;
    left: 0;
}



.NavigationSocialMediaWidget {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    height: 100%;
    margin: 0 15px;
}

.NavigationSocialMediaWidget__navBtn {
    display: grid;
    place-items: center;
    padding: 0 10px;
    color: #ddd;
    cursor: pointer;
}

.NavigationSocialMediaWidget__navBtn:hover {
    color: white;
}


.PartnersView {
    display: grid;
    place-items: center;
    padding: 50px 10px;
    color: #ddd;
}

.PartnersView--wrapper {
    width: 100%;
    padding: 50px 10px;
}

.PartnerLogo {
    display: grid;
    place-items: center;
    opacity: 0.75;
}

.PartnersView__title {
    padding: 35px 0;
}

.PartnerLogo:hover {
    opacity: 1;
    cursor: pointer;
}

.PartnerLogo svg {
    object-fit: contain;
    width: 100px;
    max-height: 85px;
}



.MobileNavigationMenuWidget {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(12px);
    cursor: auto;
    -webkit-align-content: center;
            align-content: center;
    z-index: 1;
}

.MobileNavigationMenuWidget__navBtns--wrapper {
    position: absolute;
    top: 15vh;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    place-items: center;
    grid-gap: 35px;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__navBtn {
    font-size: 1.9rem;
    font-weight: normal;
    color: #ddd;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__navBtn:hover {
    cursor: pointer;
    color: #fff;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__backBtn {
    font-size: 1.1rem;
    font-weight: normal;
    padding: 2px 5px;
    color: #ddd;
    border-radius: 180px;
    border: #ddd 2px solid;

    width: 10ch;
    margin-top: 10px;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__backBtn--wrapper {
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__backBtn:hover {
    color: #222;
    background: #aaa;
    border: #aaa 3px solid;
    cursor: pointer;
}

.MobileNavigationMenuWidget__socialMedia {
    position: absolute;
    top: 15px;
    width: 100%;
    display: grid;
    place-items: center;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

@-webkit-keyframes slideIn {
    0% {
        margin-left: -300px;
    }
    100% {
        margin-left: 0;
    }
}

@keyframes slideIn {
    0% {
        margin-left: -300px;
    }
    100% {
        margin-left: 0;
    }
}

.DownloadFab {
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 0.4;
}

.DownloadFab__container {
    width: 100%;
    display: grid;
    place-items: start;
    padding-left: 20px;
}

.DownloadFab__button {
    --fabAccentColor: white;
    display: grid;
    place-items: center;
    padding: 5px 15px;
    color: var(--fabAccentColor);
    border-radius: 180px;
    border: var(--fabAccentColor) 2px solid;
    background: rgba(25, 25, 25, 0.7);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    -webkit-animation: 600ms ease-in-out slideIn;
            animation: 600ms ease-in-out slideIn;
}

.DownloadFab__button h5 {
    font-size: 16px;
}

.DownloadFab__button:hover {
    --fabAccentColor: white;
    background: rgba(120, 120, 120, 0.5);
    cursor: pointer;
}


.MobileHeaderLayout {
    --MobileHeaderLayoutHeight: 55px;

    min-height: var(--MobileHeaderLayoutHeight);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-inline: 20px;
}

.MobileHeaderLayout .MobileNavigationWidget__menuIcon {
    display: grid;
    place-items: center;
    min-height: var(--MobileHeaderLayoutHeight);
    cursor: pointer;
    min-width: 35px;
    width: 100%;
}

.MobileHeaderLayout .MobileNavigationWidget__menuIcon svg {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}

.MobileHeaderLayout .MobileNavigationWidget {
}

.MobileHeaderLayout .LogoWidget > svg {
    max-height: 22px;
    max-width: 210px;
}

.MobileHeaderLayout .LogoIcon {
    display: grid;
    place-items: center;
    padding-right: 12px;
}

.MobileHeaderLayout .LogoIcon svg {
    height: 22px;
}

.MobileHeaderLayout .LogoWidget {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: start;
            justify-content: start;
    cursor: pointer;
}


.DesktopHeaderLayout {
    --DesktopHeaderLayoutHeight: 70px;

    min-height: var(--DesktopHeaderLayoutHeight);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px;
}

.DesktopHeaderLayout .LogoWidget {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.DesktopHeaderLayout .LogoIcon {
    --LogoIconHeight: 29px;
    display: grid;
    place-items: center;
    height: var(--LogoIconHeight);
    padding: 0 14px 0 25px;
}
.DesktopHeaderLayout .LogoIcon svg {
    height: var(--LogoIconHeight);
    display: grid;
    place-items: center;
}

.DesktopHeaderLayout .NavigationWidget {
    display: -webkit-flex;
    display: flex;
}

.NavigationWidget__navBtn {
    margin: 0 1ch;
    padding: 0.5ch 3ch;
    border-radius: 180px;
    display: grid;
    place-items: center;
    height: 100%;
}

.NavigationWidget__navBtn h5:hover {
    border-bottom: #333 3px solid;
    cursor: pointer;
    display: grid;
    place-items: center;
}



@-webkit-keyframes HeaderSlideInFromTop {
    0% {
        top: -100%;
    }
    100%{
        top: 0;
    }
}

@keyframes HeaderSlideInFromTop {
    0% {
        top: -100%;
    }
    100%{
        top: 0;
    }
}

@-webkit-keyframes HeaderSlideOutTop {
    0% {
        top: 0;
    }
    100%{
        top: -100%;
    }
}

@keyframes HeaderSlideOutTop {
    0% {
        top: 0;
    }
    100%{
        top: -100%;
    }
}

.Header {
    top: 0;
    position: fixed;
    background: rgba(144, 144, 144, 0.64);
    width: 100%;
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
    z-index: 9;
    color:black;
}

.Header.show {
    -webkit-animation: HeaderSlideInFromTop 800ms ease;
            animation: HeaderSlideInFromTop 800ms ease;
}

.Header.hide {
    -webkit-animation: HeaderSlideOutTop 800ms ease;
            animation: HeaderSlideOutTop 800ms ease;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.HeaderView {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.HeaderView__logo {
    margin-top: 3.5%;
    display: grid;
    place-items: center;
    mix-blend-mode: difference;
    color: white;
}

.HeaderView__logo--white {
    -webkit-filter: invert(1) grayscale(1) contrast(9);
            filter: invert(1) grayscale(1) contrast(9);
}
.MediaContentView {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.MediaContentView__video {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}

.MediaContentView__img {
    position: absolute;
    transition: opacity 1800ms ease 0ms;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    -webkit-animation: fadeIn 1500ms ease-in-out;
            animation: fadeIn 1500ms ease-in-out;
}

.MediaContentView__img > img {
    object-fit: cover;
    min-height: 100vh;
    min-width: 100%;
}

.LoadingWrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
}

.CrowdfundingExtCard iframe {
    height: 550px;
    max-width: 100%;
}



.OutroMessageView {
    position: absolute;
    width: 100%;
    height: calc(100vh - 20%);
    overflow: hidden;
    padding: 10% 10%;
    top: 20%;
    display: block;
    z-index: 0.88;
}

.OutroMessageView__text {
    color: white;
    font-size: 1.25em;
    width: 40%;
    -webkit-animation: fadeIn 2.4s ease-in-out;
            animation: fadeIn 2.4s ease-in-out;
}

@-webkit-keyframes LandingPageOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes LandingPageOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes LandingPageIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes LandingPageIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.LandingPageView {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
    z-index: 0;
    /*scroll-snap-align: start;*/
}

.LandingPageView.fixed {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 5;
}

.LandingPageView.show {
    -webkit-animation: LandingPageIn 600ms ease-in;
            animation: LandingPageIn 600ms ease-in;

    opacity: 1;
}

.LandingPageView.hide {
    -webkit-animation: LandingPageOut 600ms ease-in;
            animation: LandingPageOut 600ms ease-in;
    opacity: 0;
}

.scrollIcon--wrapper p {
    font-size: 14px;
}

.scrollIcon{

    box-sizing: border-box;
    border: solid 4px #ddd;
    display: block;
    position: relative;
    height: 48px;
    width: 32px;
    border-radius: 32px;
    opacity: 0.25;
}
.scrollIcon::before{
    content: "";
    width: 8px;
    height: 8px;
    background-color: #ddd;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100%;
    -webkit-animation: mouse 3s infinite;
            animation: mouse 3s infinite;
}

@-webkit-keyframes mouse{
    0%  {-webkit-transform: none;transform: none;             opacity: 1;}
    40% {-webkit-transform: translateY(16px);transform: translateY(16px);            }
    80% {-webkit-transform: translateY(16px);transform: translateY(16px); opacity: 0;}
    100%{-webkit-transform: translateY(16px);transform: translateY(16px); opacity: 0;}
}

@keyframes mouse{
    0%  {-webkit-transform: none;transform: none;             opacity: 1;}
    40% {-webkit-transform: translateY(16px);transform: translateY(16px);            }
    80% {-webkit-transform: translateY(16px);transform: translateY(16px); opacity: 0;}
    100%{-webkit-transform: translateY(16px);transform: translateY(16px); opacity: 0;}
}

.scrollIcon__container {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: grid;
    place-items: center;
}

.scrollIcon--wrapper {
    position: absolute;
    bottom: 35px;
    width: 100%;
    display: grid;
    place-items: center;
    color: #555;
}



.FooterView {
    min-height: 150px;
    max-height: 100vh;
    overflow: hidden;
    display: grid;
    place-items: center;
    background: #eee;
    padding: 45px 0;
    border-top: #ddd 15px solid;
    border-bottom: #222 15px solid;
}

.FooterLogoWidget {
    padding: 25px 0 0 0;
}

.FooterStatementWidget p {
    font-size: 0.8em;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.toast {
    position: absolute;
    display: grid;
    place-items: center;
    height: 2.5em;
    top: 90vh;
    left: auto;
    right: auto;
    border-radius: 25px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    background: rgba(230, 230, 230, 0.7);
    padding: 10px;

    -webkit-animation: fadeIn 500ms ease-in;

            animation: fadeIn 500ms ease-in;


    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.toast-message {
    color: #222;
}

.hide {
    opacity: 0;
    -webkit-animation: fadeOut 500ms ease-in;
            animation: fadeOut 500ms ease-in;
}




.GetNotifiedView {
    background: #eee;
    width: 35em;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 2%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
}

.GetNotifiedView--mobile {
    background: #eee;
    width: 95%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 2%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
}

.GetNotifiedView--background {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(200, 200, 200, 0.7);
    z-index: 10;
    display: grid;
    place-items: center;


    -webkit-backdrop-filter: blur(8px);
    -moz-backdrop-filter: blur(8px);
    -o-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
}

.RegisterViewWidget h2 {
    border-bottom: #ddd 1px solid;
    margin-bottom: 4%;
    padding: 2%;
}

.RegisterViewWidget h5 {
    margin: 2% 3% 5%;
}

.RegisterViewWidget > p {
    margin: 2%;
}

.RegisterViewWidget .separator {
    height: 0.2em;
}

.RegisterViewWidget form {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-top: 2%;
}

.RegisterViewWidget .field {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.GetNotifiedView button {
    position: relative;
}

.SuccessViewWidget {
    color: #000;
    position: absolute;
    /*background: #21bf73;*/
    background: #eee;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    z-index: 0.95;
    width: 35em;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 4%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    -webkit-animation: fadeIn 200ms ease-in;
            animation: fadeIn 200ms ease-in;
}

.SuccessViewWidget--mobile {
    color: #000;
    position: absolute;
    /*background: #21bf73;*/
    background: #eee;
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 0.95;
    width: 95%;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    padding: 4%;
    box-shadow: 0 8px 12px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    -webkit-animation: fadeIn 200ms ease-in;
            animation: fadeIn 200ms ease-in;
}

.SuccessViewWidget h2, .SuccessViewWidget--mobile h2 {
    margin-bottom: 7%;
    border-bottom: #555 1px solid;
}

.SuccessViewWidget h5, .SuccessViewWidget--mobile h5 {
    margin-bottom: 6%;
}

.SuccessViewWidget p, .SuccessViewWidget--mobile p {
    margin-bottom: 5%;
}

.google-btn--wrapper {
    display: grid;
    place-items: center;
    margin: 4% 0 1% 0;
}

.google-btn {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 180px;
    -webkit-border-radius: 180px;
    -moz-border-radius: 180px;
    border: #ddd 2px solid;
}

.google-btn:hover {
    cursor: pointer;
    background: #f1f1f1;
}

.google-btn img {
    padding: 2%;
    width: 4ch;
}

.google-btn p {
    width: 20ch;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Cairo', sans-serif;
  background: black;
}

html {
  margin: 0;
  padding: 0;
  background: black;
  font-family: 'Cairo', sans-serif;
  scroll-behavior: smooth;

  /*scroll-snap-type: y mandatory;*/
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 2.8em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 0.8em;
}


.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noScroll {
  overflow: hidden;
  height: 100vh;
}

.App {
  text-align: center;
  -webkit-animation: fadeIn 900ms ease-in;
          animation: fadeIn 900ms ease-in;
  /*scroll-snap-type: y mandatory;*/
  width: 100%;
  overflow: hidden;
}

