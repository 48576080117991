.GetNotifiedBtnView p {
    text-align: center;
}

.GetNotifiedBtnView {
    border-radius: 180px;
    -webkit-border-radius: 180px;
    -moz-border-radius: 180px;
    background: rgba(50, 50, 50, 0.75);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0.5em;
    width: 8em;
    position: relative;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
}


.GetNotifiedBtnView:hover {
    cursor: pointer;
    background: rgba(230, 230, 230, 0.6);
    -webkit-backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
    -o-backdrop-filter: blur(10px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: black;
    transform: scale(1.01);
}