


.MobileNavigationMenuWidget {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(12px);
    cursor: auto;
    align-content: center;
    z-index: 1;
}

.MobileNavigationMenuWidget__navBtns--wrapper {
    position: absolute;
    top: 15vh;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    place-items: center;
    grid-gap: 35px;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__navBtn {
    font-size: 1.9rem;
    font-weight: normal;
    color: #ddd;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__navBtn:hover {
    cursor: pointer;
    color: #fff;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__backBtn {
    font-size: 1.1rem;
    font-weight: normal;
    padding: 2px 5px;
    color: #ddd;
    border-radius: 180px;
    border: #ddd 2px solid;

    width: 10ch;
    margin-top: 10px;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__backBtn--wrapper {
    width: 100%;
    display: grid;
    place-items: center;
}

.MobileNavigationMenuWidget .MobileNavigationMenuWidget__backBtn:hover {
    color: #222;
    background: #aaa;
    border: #aaa 3px solid;
    cursor: pointer;
}

.MobileNavigationMenuWidget__socialMedia {
    position: absolute;
    top: 15px;
    width: 100%;
    display: grid;
    place-items: center;
    transform: scale(1.3);
}
